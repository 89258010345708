/* eslint-disable @typescript-eslint/no-throw-literal */
import axios, { AxiosError, AxiosInstance } from 'axios';
import { HttpError } from 'utils/HttpError';

export class Api {
  axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_SUBSCRIBE_BASE_URL ?? process.env.REACT_APP_SOLUTIONS_BASE_URL,
      headers: { 'x-lang': 'ru' },
    });

    this._handleError = this._handleError.bind(this);
    this.axios.interceptors.response.use((resp) => resp, this._handleError);
  }

  async _handleError(error: AxiosError): Promise<ErrorConstructor> {
    if (error.config) {
      error.config.headers.concat(error.config.headers);

      error.config.baseURL = undefined;
      return this.axios.request(error.config).then((res) => res.data);
    }

    if (error.response) {
      const data = error.response.data as { statusCode: number; message: string };
      throw new HttpError(data.statusCode, data.message);
    }

    throw new HttpError();
  }
}

export const ApiServices = new Api();
