import React from 'react';
import cl from 'classnames';

// Styles
import styles from './EquipmentSlide.module.scss';

// Typescript
import { EquipmentSlideProps } from './EquipmentSlide.props';

const EquipmentSlide: React.ForwardRefRenderFunction<HTMLImageElement, EquipmentSlideProps> = (
  { src, alt, block, size },
  ref,
) => {
  return (
    <img
      src={src}
      ref={ref}
      className={cl(styles[`${block}__slide-image`], { [styles[`${block}__slide-image_${size}`]]: size })}
      alt={alt}
    />
  );
};

export default React.forwardRef<HTMLImageElement, EquipmentSlideProps>(EquipmentSlide);
