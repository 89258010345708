import { useNavigate } from 'react-router-dom';
import React from 'react';
import cl from 'classnames';

import styles from './Button.module.scss';

// Typescript
import { ButtonProps } from './Button.props';

const Button: React.ForwardRefRenderFunction<HTMLAnchorElement, ButtonProps> = (
  { icon: Icon, loading, appearance, children, className, href, ...props },
  ref,
) => {
  const navigate = useNavigate();

  function handleClick(): void {
    if (href.includes('https') || href.includes('http')) {
      window.location.href = href;
    } else {
      navigate(href);
    }
  }

  return (
    <a
      ref={ref}
      className={cl(styles['button'], styles[`button_${appearance}`], className)}
      onClick={handleClick}
      {...props}
    >
      {Icon && <Icon className={cl(styles['button__icon'])} />}
      {children}
    </a>
  );
};

export default React.forwardRef<HTMLAnchorElement, ButtonProps>(Button);
