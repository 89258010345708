import React from 'react';

import cl from 'classnames';

// Styles
import styles from './FooterSocial.module.scss';

// Typescript
import { FooterSocialProps } from './FooterSocial.props';

const FooterSocial: React.ForwardRefRenderFunction<HTMLLIElement, FooterSocialProps> = ({ src, alt, href }, ref) => {
  return (
    <li className={cl(styles['footer__social-item'])} ref={ref}>
      <a className={cl(styles['footer__social-link'])} href={href}>
        <img src={src} className={cl(styles['footer__social-img'])} alt={alt} />
      </a>
    </li>
  );
};

export default React.forwardRef<HTMLLIElement, FooterSocialProps>(FooterSocial);
