import React from 'react';

import cl from 'classnames';

// Styles
import styles from './MainContentBall.module.scss';

// Typescript
import { MainContentBallProps } from './MainContentBall.props';

const MainContentBall: React.ForwardRefRenderFunction<HTMLDivElement, MainContentBallProps> = ({ className }, ref) => {
  return <div className={cl(styles['mainContent__decor-image'], styles[className])} ref={ref}></div>;
};

export default React.forwardRef<HTMLDivElement, MainContentBallProps>(MainContentBall);
