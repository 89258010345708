import React from 'react';
import cl from 'classnames';

import { ReactComponent as LoaderSVG } from 'assets/Images/loader-page.svg';

// Scss
import styles from './Loader.module.scss';

// Typescript
import { LoaderProps } from './Loader.props';

export const Loader: React.FC<LoaderProps> = ({ isActive, appearance = 'white', className, ...props }): JSX.Element => {
  return (
    <div
      className={cl(
        styles['loader'],
        styles[`loader_${appearance}`],
        {
          [styles['loader_active']]: isActive,
        },
        className,
      )}
      {...props}
    >
      <LoaderSVG className={cl(styles['loader__icon'])} />
    </div>
  );
};
