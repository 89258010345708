import React from 'react';
import ReactDOM from 'react-dom/client';
import { PopupProvider } from 'react-popup-manager';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// Blocks
import { App } from './blocks/App/App';

// Styles
import '@muffincolor/rms-react/dist/bundle.min.css';
import './styles/global.scss';

// Redux
import { setupStore } from 'store';

const rootElement = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (!rootElement) throw new Error('Не удалось найти root элемент');

export const store = setupStore();

rootElement.render(
  <Provider store={store}>
    <React.StrictMode>
      <PopupProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PopupProvider>
    </React.StrictMode>
  </Provider>,
);
