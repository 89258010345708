import React, { useEffect, useRef, useState } from 'react';
import { IconButton, TextButton, useOnPressButton } from '@muffincolor/rms-react';
import cl from 'classnames';

// Images
import { ReactComponent as CloseSVG } from 'assets/Icons/close.svg';
import { ReactComponent as InfoSVG } from 'assets/Icons/info.svg';
import { ReactComponent as SettingsSVG } from 'assets/Icons/settings.svg';
import { ReactComponent as PartnersSVG } from 'assets/Icons/partners.svg';

// Typescript
import { HeaderPopupProps } from './HeaderPopup.props';

// Scss
import styles from './HeaderPopup.module.scss';

export const HeaderPopup: React.FC<HeaderPopupProps> = ({ isOpen, onClose }): JSX.Element => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isRendered, setRendered] = useState(false);
  const ref = useRef(null);

  const handleEscPress = (evt: KeyboardEvent): void => {
    evt.preventDefault();
    onClose();
  };

  useOnPressButton(handleEscPress, 'Escape', isPopupVisible);

  const handleBackgroundClick = (event: React.MouseEvent<HTMLElement>): void => {
    const el = ref?.current as unknown as HTMLElement;

    // Ничего не делаем, если элемент не совпал или дочерний
    if (!el || el.contains(event.target as Node)) {
      return;
    }

    onClose();
  };

  const preventPropagationOfClick = (evt: React.MouseEvent): void => {
    evt.stopPropagation();
  };

  useEffect(() => {
    if (isOpen) {
      setRendered(true);

      // Задержка пока рендерится DOM
      setTimeout(() => {
        setPopupVisible(true);
      }, 40);
    } else {
      setPopupVisible(false);

      // Пропуск первого рендера
      if (isRendered) {
        // Задержка для анимации закрытия
        setTimeout(() => {
          setRendered(false);
        }, 300);
      }
    }
  }, [isOpen, isRendered]);

  return isRendered ? (
    <div
      onClick={preventPropagationOfClick}
      onMouseDown={handleBackgroundClick}
      className={cl('popup', styles['popup'], {
        [styles['popup_active']]: isPopupVisible,
      })}
    >
      <div className={cl(styles['popup__wrapper'])}>
        <div ref={ref} className={cl(styles['popup__content-box'])}>
          <IconButton
            appearance='fill-dark-gray'
            icon={CloseSVG}
            onClick={onClose}
            className={cl(styles['popup__close'])}
          />
          <nav className={cl(styles['popup__links'])}>
            <TextButton icon={InfoSVG} appearance='dark-gray'>
              Об инструменте
            </TextButton>
            <TextButton icon={SettingsSVG} appearance='dark-gray'>
              Решения
            </TextButton>
            <TextButton icon={PartnersSVG} appearance='dark-gray'>
              Партнеры
            </TextButton>
          </nav>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
