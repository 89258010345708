import React from 'react';
import { useLocation } from 'react-router-dom';
import cl from 'classnames';

// Styles
import styles from './HeaderLink.module.scss';

// Typescript
import { HeaderLinkProps } from './HeaderLink.props';

const HeaderLink: React.ForwardRefRenderFunction<HTMLAnchorElement, HeaderLinkProps> = (
  { href, hashLocation, value },
  ref,
) => {
  const { hash } = useLocation();

  return (
    <li className={cl(styles['header__list-item'])}>
      <a
        className={cl(styles['header__link'], {
          [styles['header__link_active']]: hash === hashLocation,
        })}
        href={href}
        ref={ref}
      >
        {value}
      </a>
    </li>
  );
};

export default React.forwardRef<HTMLAnchorElement, HeaderLinkProps>(HeaderLink);
