import { combineReducers, configureStore } from '@reduxjs/toolkit';

// Slices
import { authorizationReducer } from 'store/reducers';

// Создаем root reducer
const rootReducer = combineReducers({
  authorizationReducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const setupStore = (): any => {
  return configureStore({
    devTools: true,
    reducer: rootReducer,
    // Добавлено для отключение ошибки о передаче actions как массива
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
