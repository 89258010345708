import React from 'react';
import cl from 'classnames';

// Styles
import styles from './PartnersLogo.module.scss';

// Typescript
import { PartnersLogoProps } from './PartnersLogo.props';

const PartnersLogo: React.ForwardRefRenderFunction<HTMLLIElement, PartnersLogoProps> = ({ src, alt }, ref) => {
  return (
    <li className={cl(styles['partners__list-item'])} ref={ref}>
      <img src={src} alt={alt} className={cl(styles['partners__logo'])} />
    </li>
  );
};

export default React.forwardRef<HTMLLIElement, PartnersLogoProps>(PartnersLogo);
