import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useIsomorphicLayoutEffect } from '@muffincolor/rms-react';
import cl from 'classnames';

// Services
import { UsersAPI } from 'services';

// Components
import { Loader } from 'components/Loader/Loader';
import {
  Header,
  MainContent,
  Purpose,
  Solutions,
  Request,
  Benefits,
  Advantages,
  Partners,
  Footer,
  Equipment,
  Subscribe,
  Contacts,
} from 'blocks/index';

// Redux
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { authorizationSlice } from 'store/reducers';

// Scss
import styles from './App.module.scss';

export const App: React.FC = () => {
  const { hash } = useLocation();
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const { user, isUserRegisteredInProjectService } = useAppSelector((state) => state.authorizationReducer);

  const [isPending, setPendingStatus] = useState(true);
  const [userInfoLoadStatus, setUserInfoLoadStatus] = useState<null | 'error'>(null);

  const loadUserInfo = useCallback(async (): Promise<void> => {
    const user = await UsersAPI.getPassportUser().catch(() => setUserInfoLoadStatus('error'));

    if (user) {
      dispatch(authorizationSlice.actions.setUser(user));
      await UsersAPI.checkUserInProjectService()
        .then(() => {
          if (searchParams.get('try_redirect') && searchParams.get('try_redirect') === 'true') {
            window.location.replace(`${process.env.REACT_APP_PROJECT_URL ?? ''}`);
          }

          dispatch(authorizationSlice.actions.setUserRegisterStatus(true));
        })
        .catch(() => {
          dispatch(authorizationSlice.actions.setUserRegisterStatus(false));
        })
        .finally(() => setTimeout(() => setPendingStatus(false), 500));
    } else {
      setTimeout(() => setPendingStatus(false), 500);
    }
  }, []);

  useEffect(() => {
    void loadUserInfo();
  }, [loadUserInfo]);

  useIsomorphicLayoutEffect(() => {
    if (hash !== '#' && hash !== '') {
      const target = document.querySelector(hash);

      if (target) {
        target.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [hash]);

  return (
    <>
      <Header />
      <main className={cl(styles['main'])}>
        <Loader
          isActive={
            ((user === null || isUserRegisteredInProjectService === null) && userInfoLoadStatus !== 'error') ||
            isPending
          }
          appearance='white'
          className={cl(styles['main__loader'])}
        />
        <MainContent />
        <Purpose />
        <Solutions />
        <Request />
        <Benefits />
        <Advantages />
        <Partners />
        <Equipment />
        <Subscribe />
        <Contacts />
      </main>
      <Footer />
    </>
  );
};
