import axios from 'axios';

import { Api } from './Api';

// Typescript
import { ISolutionsData } from 'typings/SolutionsData';

class SolutionsAPI extends Api {
  async loadCards(): Promise<ISolutionsData> {
    return axios
      .get(process.env.REACT_APP_SOLUTIONS_BASE_URL ?? '', {
        headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0' },
      })
      .then((res) => res.data)
      .catch(this._handleError);
  }
}

export default new SolutionsAPI();
