import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'typings/Users';

// 1. Типизируем состояние
interface AuthorizationState {
  // Токен текущего пользователя
  userAuthToken: string | null;
  // Объект токенов приложение { "clientId": "client token" }
  user: IUser | null;
  // Индикатор, показывающий, зарегистрирован пользователь в базе сервиса РМС Проект или нет
  isUserRegisteredInProjectService: boolean | null;
}

// 2. Подключаем и устанавливаем начальные значения состояния
const initialState: AuthorizationState = {
  userAuthToken: null,
  user: null,
  isUserRegisteredInProjectService: null,
};

// 3. Описываем функции для слайса (редьюсеры)
export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    setUserAuthToken(state, action: PayloadAction<string | null>) {
      state.userAuthToken = action.payload;
    },
    setUser(state, action: PayloadAction<IUser | null>) {
      state.user = action.payload;
    },
    setUserRegisterStatus(state, action: PayloadAction<boolean>) {
      state.isUserRegisteredInProjectService = action.payload;
    },
  },
});

// 4. Экспортируем и подключаем импортом там, где необходимо вызвать
export default authorizationSlice.reducer;
