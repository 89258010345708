import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, LanguageSelectionField, RenderWhen } from '@muffincolor/rms-react';
import { usePopupManager } from 'react-popup-manager';
import cl from 'classnames';

// Services
import { UsersAPI } from 'services';

// Popups
import { HeaderPopup } from 'popups';
// Components
import { Button as LinkButton } from 'components/Button';
import { HeaderLink } from 'components/HeaderLink';
// Images
import HeaderLogoSVG from 'assets/Images/header-logo.svg';
import { ReactComponent as HeaderMenuBurgerSVG } from 'assets/Icons/header-menu-burger.svg';

// Styles
import styles from './Header.module.scss';

// Redux
import { useAppSelector } from 'hooks/redux';

const availableHeaderLinks = [
  { href: '#purpose', hashLocation: '#purpose', value: 'Об инструменте' },
  { href: '#solutions', hashLocation: '#solutions', value: 'Решения' },
  { href: '#partners', hashLocation: '#partners', value: 'Партнёры' },
];

export const Header: React.FC = (): JSX.Element => {
  const popupManager = usePopupManager();

  const { user, isUserRegisteredInProjectService } = useAppSelector((state) => state.authorizationReducer);

  const [language, setLanguage] = useState<string>('RU');
  const [isHeaderScrollable, setHeaderScrollable] = useState(false);
  const listenScrollEvent = (): void => {
    window.scrollY > 10 ? setHeaderScrollable(true) : setHeaderScrollable(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  const handleLangChange = (newLanguage: string): void => {
    setLanguage(newLanguage);
  };

  const handleBurgerClick = (): void => {
    popupManager.open(HeaderPopup, {
      onClose: () => {},
    });
  };

  const handleGoToProfileClick = useCallback(() => {
    if (isUserRegisteredInProjectService === false) {
      void UsersAPI.registerInProjectService().then(() => {
        window.open(`${process.env.REACT_APP_PROJECT_URL as string}`);
      });

      return;
    }

    window.open(`${process.env.REACT_APP_PROJECT_URL as string}`);
  }, [isUserRegisteredInProjectService]);

  const HeaderLinks = useMemo(
    () =>
      availableHeaderLinks.map((value, index) => (
        <HeaderLink
          href={value.href}
          hashLocation={value.hashLocation}
          value={value.value}
          key={`${value.value}.${index}`}
        />
      )),
    [availableHeaderLinks],
  );

  return (
    <header
      className={cl(styles['header'], {
        [styles['header_scrollable']]: isHeaderScrollable,
      })}
    >
      <div className={cl(styles['header__content'])}>
        <a
          href={process.env.REACT_APP_HEADER_LOGO_URL}
          target='_blank'
          rel='noreferrer'
          className={cl(styles['header__logo-link'])}
        >
          <img src={HeaderLogoSVG} className={cl(styles['header__logo-image'])} alt='Лого' />
        </a>
        <nav className={cl(styles['header__nav'])}>
          <ul className={cl(styles['header__links-list'])}>{HeaderLinks}</ul>
        </nav>
        <div className={cl(styles['header__buttons'])}>
          <LanguageSelectionField
            onLangChange={handleLangChange}
            availableLanguages={{ RU: 'Русский' }}
            selectedLanguage={language}
            className={cl(styles['header__lang-select'])}
          />

          <RenderWhen>
            <RenderWhen.If isTrue={user !== null}>
              <Button
                size='m'
                type='button'
                appearance='primary'
                className={cl(styles['header__button_sign-in'])}
                onClick={isUserRegisteredInProjectService !== null ? handleGoToProfileClick : () => {}}
              >
                В профиль
              </Button>
            </RenderWhen.If>
            <RenderWhen.If isTrue={user === null}>
              <>
                <LinkButton
                  appearance='primary'
                  href={`${process.env.REACT_APP_HEADER_SIGN_IN_URL as string}?referer=${window.location.href}`}
                  className={cl(styles['header__button_sign-in'])}
                  target='_blank'
                >
                  Войти
                </LinkButton>

                <LinkButton
                  appearance='secondary'
                  href={`${process.env.REACT_APP_HEADER_SIGN_UP_URL as string}?referer=${window.location.href}`}
                  target='_blank'
                  className={cl(styles['header__button_sign-up'])}
                >
                  Зарегистрироваться
                </LinkButton>
              </>
            </RenderWhen.If>
          </RenderWhen>

          <HeaderMenuBurgerSVG onClick={handleBurgerClick} className={cl(styles['header__burger'])} />
        </div>
      </div>
    </header>
  );
};
