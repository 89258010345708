import React, { FC } from 'react';
import cl from 'classnames';

// Styles
import styles from './Purpose.module.scss';

export const Purpose: FC = () => {
  return (
    <section className={cl(styles['purpose'])} id='purpose'>
      <div className={cl(styles['purpose__content'])}>
        <h2 className={cl(styles['purpose__heading'])}>Полезные инструменты для разных отраслей</h2>
        <p className={cl(styles['purpose__desc'])}>
          В&nbsp;зависимости от&nbsp;индустрии, в&nbsp;которой работает ваша компания, существуют разные сценарии
          использования и&nbsp;интеграции наших решений.
        </p>
        <p className={cl(styles['purpose__desc'])}>
          Мы&nbsp;предлагаем комплексное, многофункциональное и&nbsp;доступное решение, способное в&nbsp;рамках одного
          проекта решить до&nbsp;95% всех задач по&nbsp;автоматизации.
        </p>
      </div>
    </section>
  );
};
