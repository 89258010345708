import React, { FC, useMemo } from 'react';
import cl from 'classnames';

// Styles
import styles from './Benefits.module.scss';

// Components
import { BenefitsCard } from 'components/BenefitsCard';

// Icons
import BenefitsPriceSVG from 'assets/Icons/benefits-price.svg';
import BenefitsInterfaceSVG from 'assets/Icons/benefits-interface.svg';
import BenefitsAutoSVG from 'assets/Icons/benefits-auto.svg';
import BenefitsModernSVG from 'assets/Icons/benefits-modern.svg';

const availableCards = [
  {
    src: BenefitsPriceSVG,
    alt: 'иконка цена',
    headingValue: 'Низкие временные затраты',
    descValue: `Конфигуратор автоматически подбирает необходимое оборудования и добавляет актуальные тарифные цены`,
  },
  {
    src: BenefitsInterfaceSVG,
    alt: 'иконка интерфейс',
    headingValue: 'Простой и понятный интерфейс',
    descValue: `Данный инструмент объединяет в себе лучшие практики проектирования пользовательского опыта, что
      делает его простым для восприятия`,
  },
  {
    src: BenefitsAutoSVG,
    alt: 'иконка автоматизация',
    headingValue: 'Автоматический подбор оборудования',
    descValue: `Инструмент позволяет за считанные минуты сгенерировать требуемое решение и избежать ошибок в проектировании`,
  },
  {
    src: BenefitsModernSVG,
    alt: 'иконка современность',
    headingValue: 'Современные инженерные решения',
    descValue: `Математическая модель расчета и подбора оборудования создает лучшие в своем классе решения
      в разных ценовых сегментах`,
  },
];

export const Benefits: FC = () => {
  const BenefitsCards = useMemo(
    () =>
      availableCards.map((value, index) => (
        <BenefitsCard
          key={`${value.headingValue}.${index}`}
          src={value.src}
          alt={value.alt}
          headingValue={value.headingValue}
          descValue={value.descValue}
        />
      )),
    [availableCards],
  );

  return (
    <section className={cl(styles['benefits'])}>
      <div className={cl(styles['benefits__content'])}>
        <h2 className={cl(styles['benefits__heading'])}>Преимущества применения конфигуратора</h2>
        <div className={cl(styles['benefits__container'])}>{BenefitsCards}</div>
      </div>
    </section>
  );
};
