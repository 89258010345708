import React, { FC } from 'react';
import cl from 'classnames';

// Styles
import styles from './Contacts.module.scss';

// Images
import HeaderLogoSVG from 'assets/Images/header-logo.svg';

export const Contacts: FC = () => {
  return (
    <section className={cl(styles['contacts'])}>
      <div className={cl(styles['contacts__content'])}>
        <a
          href={process.env.REACT_APP_RMS_URL}
          target='_blank'
          rel='noreferrer'
          className={cl(styles['contacts__logo-link'])}
        >
          <img src={HeaderLogoSVG} className={cl(styles['contacts__logo'])} alt='Лого контакты' />
        </a>
        <ul className={cl(styles['contacts__list'])}>
          <li className={cl(styles['contacts__list-item'])}>
            <a className={cl(styles['contacts__tel'])} href='tel:+74950035728'>
              +7 495 003-57-28
            </a>
          </li>
          <li className={cl(styles['contacts__list-item'])}>
            <a className={cl(styles['contacts__email'])} href='mailto:info@rmgroup.pro'>
              info@rmgroup.pro
            </a>
          </li>
          <li className={cl(styles['contacts__list-item'])}>
            <address className={cl(styles['contacts__address'])}>115477, Москва, Деловая ул. д. 18, стр. 1</address>
          </li>
        </ul>
      </div>
    </section>
  );
};
