import React, { useState } from 'react';
import cl from 'classnames';

// Styles
import styles from './SolutionsCard.module.scss';

// Typescript
import { SolutionsCardProps } from './SolutionsCard.props';

const SolutionsCard: React.ForwardRefRenderFunction<HTMLLIElement, SolutionsCardProps> = (
  { src, headingValue, subtitleValue, backgroundColor, hoverStyle },
  ref,
) => {
  const [hover, setHover] = useState(false);

  const inlineStyles = {
    hoverStyleObj: hover ? { hover: hoverStyle } : { hover: null },
    backgroundStyleObj: { background: backgroundColor },
  };

  const handleHoverEnter = (): void => {
    setHover(true);
  };

  const handleHoverLeave = (): void => {
    setHover(false);
  };

  return (
    <li
      className={cl(styles['solutions-card'])}
      style={Object.assign({}, inlineStyles.hoverStyleObj, inlineStyles.backgroundStyleObj)}
      onMouseEnter={handleHoverEnter}
      onMouseLeave={handleHoverLeave}
      ref={ref}
    >
      <h3 className={cl(styles['solutions-card__title'])}>{headingValue}</h3>
      <p className={cl(styles['solutions-card__text'])}>{subtitleValue}</p>

      {/* <button onClick={openModal} type='button' className={cl(styles['solutions__card-button'])}>
        <img src={ArrowNextSVG} alt='стрелка' className={cl(styles['solutions__card-button-image'])} />
      </button> */}

      <img className={cl(styles['solutions-card__image'])} src={src} alt='иконка' />
    </li>
  );
};

export default React.forwardRef<HTMLLIElement, SolutionsCardProps>(SolutionsCard);
