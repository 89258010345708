import React, { FormEvent, useState } from 'react';
import { TextField } from '@muffincolor/rms-react';
import cl from 'classnames';

// Styles
import styles from './Subscribe.module.scss';

// Services
import SubscribeAPI from 'services/Subscribe';

export const Subscribe: React.FC = () => {
  const [inputData, setInputData] = useState({ value: '' });
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState(true);

  const [isFormSubmitted, setFormSubmitStatus] = useState(false);

  const handleInputChange = (evt: { target: { value: string } }): void => {
    if (!emailValidation(evt.target.value)) {
      setIsValid(false);
      setError('Неверный формат email');
    } else {
      setIsValid(true);
      setError('');
    }

    setInputData({ value: evt.target.value });
  };

  const emailValidation = (email: string): boolean => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleSubmit = (evt: FormEvent): void => {
    evt.preventDefault();

    SubscribeAPI.sendInputData(inputData)
      .then(() => setFormSubmitStatus(true))
      .catch(() => setFormSubmitStatus(false));
  };

  return (
    <section className={cl(styles['subscribe'])}>
      <div className={cl(styles['subscribe__content'])}>
        <form className={cl(styles['subscribe__form'])} onSubmit={handleSubmit}>
          <h2 className={cl(styles['subscribe__heading'])}>Подписывайтесь на&nbsp;наши&nbsp;обновления</h2>
          <p className={cl(styles['subscribe__desc'])}>
            Подпишитесь на&nbsp;нашу новостную рассылку, чтобы узнавать о&nbsp;новых решениях, вендорах
            и&nbsp;изменениях в&nbsp;сфере автоматизации
          </p>
          {isFormSubmitted ? (
            <div className={cl(styles['subscribe__success-container'])}>
              <p className={cl(styles['subscribe__success-text'])}>Подписка на новостную рассылку успешно оформлена</p>
            </div>
          ) : (
            <>
              <div className={cl(styles['subscribe__input-container'])}>
                <TextField
                  size='m'
                  name='email'
                  type='email'
                  placeholder='Адрес электронной почты'
                  error={error}
                  value={inputData.value}
                  onChange={handleInputChange}
                  required
                />
                <button className={cl(styles['subscribe__button'])} type='submit' disabled={!isValid}>
                  Подписаться
                </button>
              </div>
            </>
          )}
        </form>
      </div>
    </section>
  );
};
