import React from 'react';

import cl from 'classnames';

// Styles
import styles from './BenefitsCard.module.scss';

// Typescript
import { BenefitsCardProps } from './BenefitsCard.props';

const BenefitsCard: React.ForwardRefRenderFunction<HTMLDivElement, BenefitsCardProps> = (
  { src, alt, headingValue, descValue },
  ref,
) => {
  return (
    <div className={cl(styles['benefits__item'])} ref={ref}>
      <img className={cl(styles['benefits__item-image'])} src={src} alt={alt} />
      <div className={cl(styles['benefits__item-text-content'])}>
        <h3 className={cl(styles['benefits__item-heading'])}>{headingValue}</h3>
        <p className={cl(styles['benefits__item-desc'])}>{descValue}</p>
      </div>
    </div>
  );
};

export default React.forwardRef<HTMLDivElement, BenefitsCardProps>(BenefitsCard);
