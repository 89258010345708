import React from 'react';
import cl from 'classnames';

// Images
import AdvantageGraphsImage from 'assets/Images/advantages-graphs.svg';
import AdvantageSpiralImage from 'assets/Images/advantages-spiral.svg';

// Styles
import styles from './Advantages.module.scss';

// Components
import { Button } from 'components/Button';

export const Advantages: React.FC = () => {
  return (
    <section className={cl(styles['advantages'])}>
      <div className={cl(styles['advantages__content'])}>
        <div className={cl(styles['advantages__first-block'])}>
          <div className={cl(styles['advantages__text-content'])}>
            <h2 className={cl(styles['advantages__heading'])}>Единая система</h2>
            <p className={cl(styles['advantages__desc'], styles['advantages__desc_top'])}>
              Пользователь работает в&nbsp;рамках единой системы,&nbsp;что позволяет бесшовно создавать
              и&nbsp;дорабатывать проекты. Все компоненты вашего проекта составляют единое IT&nbsp;решение,&nbsp;что
              упрощает работу с&nbsp;системой и&nbsp;не&nbsp;требует использования дополнительных сервисов.
            </p>

            <Button appearance='secondary' href='#solutions' className={cl(styles['advantages__button'])}>
              Подробнее о решениях
            </Button>
          </div>
          <img className={cl(styles['advantages__graph-image'])} src={AdvantageGraphsImage} />
        </div>
      </div>
      <div className={cl(styles['advantages__content'])}>
        <div className={cl(styles['advantages__second-block'])}>
          <img className={cl(styles['advantages__spiral-image'])} src={AdvantageSpiralImage} />
          <div className={cl(styles['advantages__text-content'])}>
            <h2 className={cl(styles['advantages__heading'])}>Облачное хранилище</h2>
            <p className={cl(styles['advantages__desc'], styles['advantages__desc_bottom'])}>
              Поддерживает контейнеризацию, изоляцию процессов и&nbsp;быстрый доступ&nbsp;к данным вне&nbsp;зависимости
              от&nbsp;их&nbsp;класса хранения и&nbsp;уровня пользовательской нагрузки. Обслуживается инженерами
              со&nbsp;специализированным опытом в&nbsp;эксплуатации систем такого типа.
            </p>
            <Button
              appearance='secondary'
              href={process.env.REACT_APP_ADVANTAGES_CLOUD_STORAGE_URL as string}
              className={cl(styles['advantages__button'], styles['advantages__button_second'])}
            >
              Подробнее о решениях
            </Button>
            {/* <div className={cl(styles['advantages__circle-image'])}></div> */}
          </div>
        </div>
      </div>
    </section>
  );
};
