import axios, { AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

// Redux
import { authorizationSlice } from 'store/reducers';
import { store } from '..';

// Typescript
import { IProjectUser, IUser } from 'typings/Users';

class UsersAPI {
  axios: AxiosInstance;

  constructor(config: AxiosRequestConfig = {}) {
    this._extendHeaders = this._extendHeaders.bind(this);

    this.axios = axios.create(config);

    this.axios.interceptors.request.use(this._extendHeaders);
  }

  // Пре-реквест обработчик для установки заголовков авторизации
  async _extendHeaders(config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> {
    const { authorizationReducer } = store.getState();
    const { userAuthToken } = authorizationReducer;

    // Создаем переменную токена
    let requestToken = userAuthToken;

    // Если токена до этого не было, то запрашиваем новый и сохраняем в redux
    if (!userAuthToken) {
      requestToken = await this._refreshUserToken();
    }

    config.headers.set('Authorization', 'Bearer ' + requestToken);

    return config;
  }

  async _refreshUserToken(): Promise<string> {
    // Если fingerprint не сохранен, запрашиваем его и сохраняем в sessionStorage
    if (!Cookies.get('rms_fingerprint')) {
      window.location.href = `${process.env.REACT_APP_PASSPORT_AUTH_URL ?? ''}?referer=${window.location.href}`;
    }

    // Заголовки используются для авторизации в режиме разработки
    const headers: Record<string, string> =
      process.env.NODE_ENV === 'development' ? { 'x-real-ip': '176.195.39.81' } : {};

    // Отправляем запрос на обновление токена
    const response: { authToken: string } = await axios
      .get(`${process.env.REACT_APP_PASSPORT_API ?? ''}/sessions/users/actions/refresh-tokens`, {
        withCredentials: true,
        headers: { 'x-user-fingerprint': Cookies.get('rms_fingerprint') ?? '', ...headers },
      })
      .then((res) => res.data);

    // Если токен успешно получен, сохраняем его в redux
    if (response?.authToken) {
      store.dispatch(authorizationSlice.actions.setUserAuthToken(response.authToken));
    }

    return response.authToken;
  }

  async getPassportUser(): Promise<IUser> {
    return this.axios.get(`/users/me`).then((res) => res.data);
  }

  async checkUserInProjectService(): Promise<IProjectUser> {
    return this.axios.get(`${process.env.REACT_APP_PROJECT_API ?? ''}/users/me`).then((res) => res.data);
  }

  async registerInProjectService(): Promise<IProjectUser> {
    return this.axios.post(`${process.env.REACT_APP_PROJECT_API ?? ''}/users`).then((res) => res.data);
  }
}

export default new UsersAPI({ baseURL: process.env.REACT_APP_PASSPORT_API, withCredentials: true });
