import React, { FC, useMemo } from 'react';
import cl from 'classnames';

// Styles
import styles from './Partners.module.scss';

// Components
import { PartnersLogo } from 'components/PartnersLogo';

// Images
import DKCLogoSVG from 'assets/Images/partners-dkc.svg';
import SSLogoSVG from 'assets/Images/partners-s+s.svg';
import OvenLogoSVG from 'assets/Images/partners-oven.svg';
import DHLogoSVG from 'assets/Images/partners-daichi.svg';
import SELogoSVG from 'assets/Images/partners-systeme.svg';
import ChintLogoSVG from 'assets/Images/partners-chint.svg';
import KEAZLogoSVG from 'assets/Images/partners-keaz.svg';
import CarelLogoWebp from 'assets/Images/partners-carel.webp';
import EKFLogoSVG from 'assets/Images/partners-ekf.svg';
import DKLogoSVG from 'assets/Images/partners-dekraft.svg';

const availableLogos = [
  { src: CarelLogoWebp, alt: 'CAREL лого' },
  { src: DKCLogoSVG, alt: 'DKC лого' },
  { src: DHLogoSVG, alt: 'DH лого' },
  { src: SELogoSVG, alt: 'SE лого' },
  { src: DKLogoSVG, alt: 'DK лого' },
  { src: OvenLogoSVG, alt: 'Oven лого' },
  { src: ChintLogoSVG, alt: 'CHINT лого' },
  { src: KEAZLogoSVG, alt: 'KEAZ лого' },
  { src: SSLogoSVG, alt: 'S+S лого' },
  { src: EKFLogoSVG, alt: 'EKF лого' },
];

export const Partners: FC = () => {
  const PartnersLogos = useMemo(
    () => availableLogos.map((value, index) => <PartnersLogo src={value.src} alt={value.alt} key={index} />),
    [availableLogos],
  );

  return (
    <section className={cl(styles['partners'])} id={'partners'}>
      <div className={cl(styles['partners__content'])}>
        {' '}
        <h2 className={cl(styles['partners__heading'])}>Наши партнеры</h2>
        <ul className={cl(styles['partners__list'])}>{PartnersLogos}</ul>
      </div>
    </section>
  );
};
