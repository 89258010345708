import React, { FC, useMemo } from 'react';
import cl from 'classnames';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';

import 'pure-react-carousel/dist/react-carousel.es.css';

// Styles
import styles from './Equipment.module.scss';

// Components
import { EquipmentSlide } from 'components/EquipmentSlide';

// Images
import FirstSlideJPG from 'assets/Images/equipment-first-slide.webp';
import SecondSlideJPG from 'assets/Images/equipment-second-slide.webp';
import ThirdSlideJPG from 'assets/Images/equipment-third-slide.webp';
import FourthSlideJPG from 'assets/Images/equipment-fourth-slide.webp';
import FifthSlideJPG from 'assets/Images/equipment-fifth-slide.webp';
import SixthSlideJPG from 'assets/Images/equipment-sixth-slide.webp';
import SeventhSlideJPG from 'assets/Images/equipment-seventh-slide.webp';
import EighthSlideJPG from 'assets/Images/equipment-8th-slide.webp';
import NinthSlideJPG from 'assets/Images/equipment-9th-slide.webp';
import TenthSlideJPG from 'assets/Images/equipment-10th-slide.webp';

// Icons
import ArrowBackSVG from 'assets/Icons/equipment-back.svg';
import ArrowNextSVG from 'assets/Icons/equipment-next.svg';

const availableSlides = [
  { src: FirstSlideJPG, alt: 'Слайд 1' },
  { src: SecondSlideJPG, alt: 'Слайд 2' },
  { src: ThirdSlideJPG, alt: 'Слайд 3' },
  { src: FourthSlideJPG, alt: 'Слайд 4' },
  { src: FifthSlideJPG, alt: 'Слайд 5' },
  { src: SixthSlideJPG, alt: 'Слайд 6' },
  { src: SeventhSlideJPG, alt: 'Слайд 7' },
  { src: EighthSlideJPG, alt: 'Слайд 8' },
  { src: NinthSlideJPG, alt: 'Слайд 9' },
  { src: TenthSlideJPG, alt: 'Слайд 10' },
];

export const Equipment: FC = () => {
  const EquipmentSlides = useMemo(
    () =>
      availableSlides.map((value, index) => (
        <Slide
          key={`${value.alt}.${index}`}
          index={index}
          className={cl(styles['equipment__slide'])}
          innerClassName={cl(styles['equipment__inner-slide'])}
        >
          <EquipmentSlide src={value.src} alt={value.alt} block={'equipment'} size={'default'} />
        </Slide>
      )),
    [availableSlides],
  );

  return (
    <section className={cl(styles['equipment'])}>
      <div className={cl(styles['equipment__content'])}>
        <CarouselProvider
          naturalSlideWidth={332}
          naturalSlideHeight={400}
          visibleSlides={3.28}
          totalSlides={10}
          infinite={true}
        >
          <div className={cl(styles['equipment__flex-container'])}>
            <h2 className={cl(styles['equipment__heading'])}>Применяемое оборудование</h2>
            <div className={cl(styles['equipment__buttons'])}>
              <ButtonBack className={cl(styles['equipment__slider-button'], styles['button-back'])}>
                <img src={ArrowBackSVG} alt='назад' />
              </ButtonBack>
              <ButtonNext className={cl(styles['equipment__slider-button'], styles['button-next'])}>
                <img src={ArrowNextSVG} alt='вперед' />
              </ButtonNext>
            </div>
          </div>
          <Slider className={cl(styles['equipment__slider'])} classNameTray={cl(styles['equipment__slider-tray'])}>
            {EquipmentSlides}
          </Slider>
        </CarouselProvider>
      </div>
    </section>
  );
};
