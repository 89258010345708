import React, { useState, useEffect, useMemo } from 'react';
import cl from 'classnames';

// Services
import SolutionsAPI from 'services/Solutions';

// Components
import { SolutionsCard } from 'components/SolutionsCard';

// Styles
import styles from './Solutions.module.scss';

// TypeScript
import { ISolutionsData, SolutionCard } from 'typings/SolutionsData';

export const Solutions: React.FC = () => {
  const [solutionsData, setSolutionsData] = useState<ISolutionsData>();

  const loadSolutionsCards = async (): Promise<void> => {
    await SolutionsAPI.loadCards().then((res) => setSolutionsData(res));
  };

  useEffect(() => {
    void loadSolutionsCards();
  }, []);

  const availableCards = solutionsData ? solutionsData.solutions : [];

  const SolutionsCards = useMemo(
    () =>
      availableCards.map((value: SolutionCard, index) => (
        <SolutionsCard
          key={`${value.title}.${index}`}
          headingValue={value.title}
          subtitleValue={value.subtitle}
          descValues={value.descriptions}
          backgroundColor={value.style.background}
          hoverStyle={value.style.hover}
          src={value.iconSrc}
          imagesValues={value.images}
        />
      )),
    [solutionsData],
  );

  return (
    <section className={cl(styles['solutions'])} id='solutions'>
      <ul className={cl(styles['solutions__list'])}>{SolutionsCards}</ul>
    </section>
  );
};
