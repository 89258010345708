import React, { useMemo } from 'react';
import cl from 'classnames';
import { Button } from '@muffincolor/rms-react';

// Styles
import styles from './MainContent.module.scss';

// Components
import { MainContentBall } from 'components/MainContentBall';

const availableBalls = [
  { className: 'idea-image' },
  { className: 'switches-image' },
  { className: 'bearing-image' },
  { className: 'lock-image' },
  { className: 'cloud-image' },
  { className: 'folder-image' },
  { className: 'stonks-image' },
  { className: 'gear-image' },
];

export const MainContent: React.FC = () => {
  const MainContentBalls = useMemo(
    () => availableBalls.map((value, index) => <MainContentBall className={value.className} key={index} />),
    [availableBalls],
  );

  const handleCTAButtonClick = (): void => {
    window.open(process.env.REACT_APP_PROJECT_URL as string);
  };

  return (
    <section className={cl(styles['mainContent'])}>
      <div className={cl(styles['mainContent__content'])}>
        <div className={cl(styles['mainContent__text-block'])}>
          <h1 className={cl(styles['mainContent__heading'])}>Готовые решения для&nbsp;вашего&nbsp;бизнеса</h1>
          <p className={cl(styles['mainContent__desc'])}>
            Мы&nbsp;являемся экспертами в&nbsp;области цифровизации инженерных систем и&nbsp;предлагаем новый сервис
            по&nbsp;проектированию шкафов автоматизации
          </p>
        </div>

        <div className={cl(styles['mainContent__images-block'])}>
          <div className={cl(styles['mainContent__images'])}>
            <div className={cl(styles['mainContent__rectangle-grey'])}></div>
            <div className={cl(styles['mainContent__rectangle-blue'])}></div>
            <div className={cl(styles['mainContent__macbook-image'])}></div>
            <div className={cl(styles['mainContent__balls'])}>{MainContentBalls}</div>
          </div>
        </div>

        <Button
          type='button'
          size='m'
          appearance='primary'
          className={cl(styles['mainContent__cta-btn'])}
          onClick={handleCTAButtonClick}
        >
          Начать конфигурировать
        </Button>
      </div>
    </section>
  );
};
