import React, { FC, useMemo } from 'react';
import cl from 'classnames';

// Styles
import styles from './Footer.module.scss';

// Components
import { FooterSocial } from 'components/FooterSocial';

// Icons
import VKIconSVG from 'assets/Icons/footer-vk.svg';
import ZenIconSVG from 'assets/Icons/footer-zen.svg';

const availableSocials = [
  { src: VKIconSVG, alt: 'Иконка VK', href: process.env.REACT_APP_FOOTER_VK_URL as string },
  { src: ZenIconSVG, alt: 'Иконка Zen', href: process.env.REACT_APP_FOOTER_ZEN_URL as string },
];

export const Footer: FC = () => {
  const FooterSocials = useMemo(
    () =>
      availableSocials.map((value, index) => (
        <FooterSocial href={value.href} src={value.src} alt={value.alt} key={index} />
      )),
    [availableSocials],
  );

  return (
    <footer className={cl(styles['footer'])}>
      <div className={cl(styles['footer__content'])}>
        <ul className={cl(styles['footer__list'])}>
          <li className={cl(styles['footer__list-item'])}>
            <span className={cl(styles['footer__copyright'])}>© {new Date().getFullYear()} ООО «РМ Солюшн»</span>
          </li>
          <li className={cl(styles['footer__list-item'])}>
            <a
              className={cl(styles['footer__link-item'])}
              target='_blank'
              href={process.env.REACT_APP_FOOTER_USER_AGREEMENT_URL}
              rel='noreferrer'
            >
              Пользовательское соглашение
            </a>
          </li>
          <li className={cl(styles['footer__list-item'])}>
            <a
              className={cl(styles['footer__link-item'])}
              target='_blank'
              href={process.env.REACT_APP_FOOTER_PERSONAL_DATA_PROCESSING_RULES_URL}
              rel='noreferrer'
            >
              Правила обработки персональных данных
            </a>
          </li>
        </ul>
        <ul className={cl(styles['footer__socials'])}>{FooterSocials}</ul>
      </div>
    </footer>
  );
};
