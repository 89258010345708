import React from 'react';
import cl from 'classnames';
import { Button } from '@muffincolor/rms-react';

// Images
import RequestGraph from 'assets/Images/request-graph.svg';

// Styles
import styles from './Request.module.scss';

export const Request: React.FC = () => {
  const handleRequestButtonClick = (): void => {
    window.open(process.env.REACT_APP_PROJECT_SUPPORT_URL);
  };

  return (
    <section className={cl(styles['request'])}>
      <div className={cl(styles['request__content'])}>
        <div className={cl(styles['request__text-block'])}>
          <h2 className={cl(styles['request__heading'])}>Варианты применения</h2>
          <ul className={cl(styles['request__list'])}>
            <li className={cl(styles['request__list-item'])}>
              <span className={cl(styles['request__number'])}>01</span>
              <p className={cl(styles['request__text'])}>
                Размещение конфигуратора нашей реализации на&nbsp;вашем сайте
              </p>
            </li>
            <li className={cl(styles['request__list-item'])}>
              <span className={cl(styles['request__number'])}>02</span>
              <p className={cl(styles['request__text'])}>Интеграция наших решений на&nbsp;базе вашего сайта</p>
            </li>
          </ul>
        </div>

        <img alt='Иллюстрация' src={RequestGraph} className={cl(styles['request__image-block'])} />

        <Button
          size='m'
          type='button'
          appearance='primary'
          className={cl(styles['request__button'])}
          onClick={handleRequestButtonClick}
        >
          Оставить заявку
        </Button>
      </div>
    </section>
  );
};
