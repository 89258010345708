import axios from 'axios';

// Services
import { Api } from './Api';

class SubscribeAPI extends Api {
  async sendInputData(inputData: { value: string }): Promise<Record<string, string>> {
    return axios
      .post(process.env.REACT_APP_SUBSCRIBE_BASE_URL ?? '', inputData, {
        headers: {
          'X-Client-Id': process.env.REACT_APP_SUBSCRIBE_CLIENT_ID,
          'X-Client-Secret': process.env.REACT_APP_SUBSCRIBE_CLIENT_SECRET,
        },
      })
      .then((res) => res.data)
      .catch(this._handleError);
  }
}

export default new SubscribeAPI();
